import React, {
  FunctionComponent,
  useCallback,
  ChangeEvent,
  useState,
  useEffect,
  useRef
} from "react";
import axios from "axios";
import { navigate } from "gatsby";
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { isDev } from "../../../utils/env";
import { isEmpty } from "../../../utils/helpers";
import * as storage from "../../../utils/storage";
import HeaderQuiz from "../../HeaderQuiz";
import FooterQuiz from "../../FooterQuiz";
import Button from "../../Button";
import Field from "../../Field";
import Question from "../Question/Question";
import InfoPopover from "../../InfoPopover";
import CollapsibleList from "../../CollapsibleList";
import { getImage } from "./getImages";
import { useQuizActions, useAnswers, usePersonalData, useNavigateToNextStep } from "../Provider";
import { QuestionStep } from "../types";
import "./QuestionStep.scss";

interface AnswersRequiredListItemProps {
  opened?: boolean;
  title: string;
  list: Array<{
    value: string;
    text: string;
    correct?: boolean;
    required?: boolean;
    completed?: boolean;
  }>
}

interface AnswersRequiredListProps {
  answersRequiredList: Array<AnswersRequiredListItemProps>
}

const QuestionStepComponent: FunctionComponent<QuestionStep> = props => {
  const {
    title,
    message,
    name,
    answers,
    multipleAnswers,
    description,
    sideTitle,
    sideImage,
    activeStep,
    steps,
  } = props;

  const userAnswers = useAnswers();
  const userPersonalData = usePersonalData();
  const { setAnswer, clearData, setPersonalData } = useQuizActions();
  const [isValidatingFields, setIsValidatingFields] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isTermsCheck, setIsTermsCheck] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isRecaptchaValidation, setIsRecaptchaValidation] = useState(false);
  const [captchaValue, setCaptchaValue] = useState();
  const navigateToNextStep = useNavigateToNextStep();
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const appNameFieldRef = useRef<HTMLInputElement>(null);
  const companyNameFieldRef = useRef<HTMLInputElement>(null);
  const projectIdFieldRef = useRef<HTMLInputElement>(null);
  const descriptionFieldRef = useRef<HTMLInputElement>(null);
  const recaptchaRef = useRef();
  const userAnswerValue = userAnswers[name]?.value;
  const [disabledContinue, setDisabledContinue] = useState(false);
  const apiUrl = process.env.GATSBY_QUIZ_RESULTS_API_URL || '';
  let questionsCompleted: any = [];
  const [questionsIncompleted, setQuestionsIncompleted] = useState(false);
  const [state, setState] = useState<AnswersRequiredListProps>({ answersRequiredList: [] });
  const { answersRequiredList } = state;
  const answersList: any = [];
  const requiredList: Array<AnswersRequiredListItemProps> = [];

  useEffect(() => {
    if (
      (activeStep === 0 || activeStep === 1) &&
      (emailFieldRef.current && emailFieldRef.current.value === "") &&
      (projectIdFieldRef.current && projectIdFieldRef.current.value === "") &&
      (appNameFieldRef.current && appNameFieldRef.current.value === "")
    ) {
      setIsValidatingFields(false);
    }

    const url = new URL(window.location.href);
    const pathArray = window.location.pathname.substring(1).split('/');
    const currentQuizId = url.searchParams.get('quizId');
    const dataStorageKey = currentQuizId ? `casa_quiz_data_${currentQuizId}` : `casa_quiz_data_${pathArray[0]}`;
    const storageData = storage.getItem(dataStorageKey) && JSON.parse(storage.getItem(dataStorageKey));

    if (storageData &&  storageData.terms) {
      setIsTermsCheck(storageData.terms);
    }

    if (name === "finish") {
      const answersArr = Object.values(userAnswers);
      if (!isEmpty(userAnswers)) {
        answersArr.map(userAnswer => {
          if (userAnswer && userAnswer.completed === false) {
            questionsCompleted.push('error');
          }
        })
      }
      if (answersArr.length !== answersList.length || questionsCompleted.length > 0 || isEmpty(userAnswers)) {
        setQuestionsIncompleted(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   handleAnswersValidate();
  // }, [userAnswers]);

  const handleFieldChange = () => {
    const notValidEmail = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailFieldRef.current.value);

    if (!notValidEmail) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }

    if (
      (emailFieldRef.current.value !== "") &&
      (projectIdFieldRef.current.value !== "") &&
      (appNameFieldRef.current.value !== "" && !notValidEmail) &&
      (companyNameFieldRef.current.value !== "")
    ) {
      setIsValidatingFields(true);
    } else {
      setIsValidatingFields(false);
    }
  };

  const handleNumberFieldChange = () => {
    projectIdFieldRef.current.value = projectIdFieldRef.current.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '');
  };

  const setNewAnswers = () => {
    setIsFormSubmitting(true);

    const quizResults: {
      email?: string;
      applicationName?: string;
      companyName?: string;
      projectId?: string;
      description?: string;
      list: Array<{
        stepId: string;
        title: string;
        description?: string;
        list?: any;
      }>;
      gcaptcha?: string;
      // code?: string;
    } = {
      email: userPersonalData && userPersonalData.email,
      applicationName: userPersonalData && userPersonalData.applicationName,
      companyName: userPersonalData && userPersonalData.companyName,
      projectId: userPersonalData && userPersonalData.projectId,
      description: userPersonalData && userPersonalData.description,
      list: [],
      gcaptcha: captchaValue
      // code: userPersonalData ? userPersonalData.quizName : 'quiz-1'
    };

    steps.map(step => {
      const ansArr: any = [];

      if (step.id === "start" || step.id === "intro" || step.id === "finish") {
        return;
      }

      if (step.answers) {
        step.answers.map((answer, index) => {
          const a = [];

          answer.list.map(ans => {
            let checkedAns = null;
            if (userAnswers) {
              Object.values(userAnswers).map(userAnswer => {
                if (ans.value === userAnswer.name && userAnswer.completed) {
                  checkedAns = userAnswer.label;
                }
              })
            }
            a.push({...ans, answer: checkedAns});
          })

          ansArr.push({
            question: answer.title,
            questionId: `question-${index + 1}`,
            answer: a
          })
        })
      }

      quizResults.list.push({
        stepId: step.id,
        description: step.description,
        // title: step.title.replace(/<\/?[^>]+(>|$)/g, ""),
        title: step.title,
        list: ansArr
      })
    });

    if (isRecaptchaValidation) {
      axios.post(apiUrl, quizResults, {
        headers: { "Access-Control-Allow-Origin": "*" }
      })
        .then(function (response) {
          if (response.status === 200) {
            clearData({ answers: {} });
            navigate("/thanks");
          }
        })
        .catch(function (error) {
          setIsFormSubmitting(false);
          console.log(error);
        });
    }
  };

  const isChecked = (name, value) => userAnswers[name]?.value === value;

  const handleAnswerClick = (event) => {
    const { name, value, title } = event.target;
    const isCheck = isChecked(name, value);

    if (isCheck) {
      setAnswer({
        answerName: name,
        answer: {
          value: '',
          name: name,
          label: title,
          completed: false,
        },
      });
    }
  };

  const handleAnswerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!multipleAnswers) {
        setAnswer({
          answerName: event.target.name,
          answer: {
            value: event.target.value,
            name: event.target.name,
            label: event.target.title,
            completed: true,
          },
        });

        return;
      }

      if (event.target.checked) {
        setAnswer({
          answerName: event.target.name,
          answer: {
            value: Array.isArray(userAnswerValue)
              ? [...userAnswerValue, event.target.value]
              : [event.target.value],
            completed: false,
          },
        });

        return;
      }

      setAnswer({
        answerName: event.target.name,
        answer: {
          value: Array.isArray(userAnswerValue)
            ? userAnswerValue.filter(value => value !== event.target.value)
            : [],
          completed: false,
        },
      });
    },
    [setAnswer, userAnswerValue]
  );

  const handleContinueClick = useCallback(() => {
    if (name === "start") {
      setPersonalData({
        email: emailFieldRef.current.value,
        applicationName: appNameFieldRef.current.value,
        companyName: companyNameFieldRef.current.value,
        projectId: projectIdFieldRef.current.value,
        description: descriptionFieldRef.current.value,
        terms: true,
      });
    }

    navigateToNextStep();
  }, []);

  let stepsLength = steps.length;
  steps.map(step => {
    if (step.id === "start" || step.id === "intro" || step.id === "finish") {
      stepsLength -= 1;
    }
    return stepsLength;
  });

  steps.map(step => {
    if (step.answers) {
      step.answers.map(item => {
        let isRequired = false;
        if (item.list) {
          const arr: Array<{
            value: string;
            text: string;
            correct?: boolean;
            required?: boolean;
          }> = [];

          item.list.map(listItem => {
            if (listItem.required) {
              isRequired = true;
              arr.push({...listItem, correct: false});
            }
            answersList.push(listItem);
          });

          if (isRequired) {
            requiredList.push({
              opened: false,
              title: step.title,
              list: arr
            });
          }
        }
      });
    }
  });

  useEffect(() => {
    if (name === 'finish') {
      requiredList.map(item => {
        let isCompleted = true;
  
        item.list.map(listItem => {
          listItem.completed = userAnswers[listItem.value]?.completed || false;

          if (userAnswers[listItem.value]?.value === 'yes') {
            listItem.correct = true;
          } else {
            isCompleted = false;
          }
        });
  
        if (!isCompleted) {
          item.opened = true;
        }
      });

      setState({ answersRequiredList: requiredList });
    }
	}, []);

  const handleReCaptchaChange = (value) => {
		if (value) {
			setCaptchaValue(value);
			setIsRecaptchaValidation(true);
		}
	};

  const handleTermsCheck = () => {
    if (!isTermsCheck) {
      setIsTermsCheck(true);
    } else {
      setIsTermsCheck(false);
    }
    setPersonalData({
      email: emailFieldRef.current.value,
      applicationName: appNameFieldRef.current.value,
      companyName: companyNameFieldRef.current.value,
      projectId: projectIdFieldRef.current.value,
      description: descriptionFieldRef.current.value,
      terms: !isTermsCheck,
    });
  };

  const handleAnswersValidate = () => {
    const arr: any = [];

    if (answers) {
      answers[0].list.map(answer => {
        const validAnswer = userAnswers[answer.value]?.value?.length;
        if (!validAnswer) {
          arr.push('error');
        }
      });
    }

    if (arr.filter(item => item === 'error').length) {
      setDisabledContinue(true);
    } else {
      setDisabledContinue(false);
    }
  };

  return (
    <section className="question-step" id={name}>
      <HeaderQuiz />
      <div className="question-step__inner-wrap">
        <div className="container">
          <div className="question-step__inner">
            <div className="question-step__aside">
              {sideTitle && (
                <div className="question-step__aside-title">{sideTitle}</div>
              )}
              {sideImage && (
                <div className="question-step__image">
                  <img
                    className="question-step__image-i"
                    src={getImage(sideImage)}
                    alt=""
                    width="406"
                    height="406"
                  />
                </div>
              )}
          </div>
          <div className="question-step__content">
            <div className="question-step__head">
              {title && (
                <h2
                  className="question-step__title"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              )}
              {(activeStep > 0 && activeStep !== stepsLength) && (
                <span className="question-step__progress-counts">
                  Step&nbsp;
                  <span className="question-step__progress-counts-active">{activeStep}</span>
                  <span className="question-step__progress-counts-separator">/</span>
                  <span className="question-step__progress-counts-total">{stepsLength}</span>
                </span>
              )}
              <div className="question-step__progress">
                <div className="question-step__progress-bar" style={{width: `${(100 / stepsLength) * activeStep}%`}} />
              </div>
            </div>
            {name === "start" && (
              <div className="question-step__fields">
                <Field
                  inputRef={emailFieldRef}
                  onChange={handleFieldChange}
                  name="email"
                  type="email"
                  defaultValue={userPersonalData && userPersonalData.email}
                  label="Your email<sup>*</sup>"
                  placeholder="Your email"
                  rootClassName="question-step__field-wrap"
                  className="question-step__field"
                  error={!isValidEmail}
                  errorMessage="<sup>*</sup>Invalid field format"
                  required
                />
                <Field
                  inputRef={appNameFieldRef}
                  onChange={handleFieldChange}
                  name="applicationName"
                  type="text"
                  defaultValue={userPersonalData && userPersonalData.applicationName}
                  label="Application Name<sup>*</sup>"
                  placeholder="Your answer"
                  rootClassName="question-step__field-wrap"
                  className="question-step__field"
                  required
                />
                <Field
                  inputRef={companyNameFieldRef}
                  onChange={handleFieldChange}
                  name="companyName"
                  type="text"
                  defaultValue={userPersonalData && userPersonalData.companyName}
                  label="Company Name<sup>*</sup>"
                  placeholder="Your company name"
                  rootClassName="question-step__field-wrap"
                  className="question-step__field"
                  required
                />
                <div className="question-step__field-wrap">
                  <div className="question-step__field-wrap-hint">
                    <InfoPopover title="Where to find it?">
                      <ul>
                        <li>1. Go to the Google Developer Console: <a href="https://console.developers.google.com" target="_blank" rel="noopener noreferrer">https://console.developers.google.com</a></li>
                        <li>2. Select your project</li>
                        <li>3. Go to Cloud Overview page</li>
                        <li>4. Find your project number under project info card</li>
                      </ul>
                    </InfoPopover>
                  </div>
                  <Field
                    inputRef={projectIdFieldRef}
                    onChange={handleFieldChange}
                    onInput={handleNumberFieldChange}
                    name="projectId"
                    type="number"
                    defaultValue={userPersonalData && userPersonalData.projectId}
                    label="Project number<sup>*</sup>"
                    placeholder="Your project number"
                    rootClassName="question-step__field-wrap"
                    className="question-step__field"
                    required
                  />
                </div>
                <Field
                  inputRef={descriptionFieldRef}
                  name="description"
                  type="text"
                  defaultValue={userPersonalData && userPersonalData.description}
                  label="Please provide a few sentences describing your application. You can provide URLs to about pages or other guidance."
                  placeholder="Your answer"
                  rootClassName="question-step__field-wrap"
                  className="question-step__field"
                />
              </div>
            )}
            {description && (
              <div
                className="question-step__description"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            {answers && (
              <div className="question-step__answers">
                {answers.map(item => (
                  <div className="question-step__answers-section" key={item.title}>
                    <div className="question-step__answers-title">{item.title}</div>
                    {item.list && (
                      <ul className="question-step__answers-list">
                        {item.list.map(answer => (
                          <li
                            key={answer.value}
                            className="question-step__answers-item"
                          >
                            <Question
                              text={answer.text}
                              required={answer.required}
                              name={answer.value}
                              userAnswers={userAnswers}
                              onClick={handleAnswerClick}
                              onChange={handleAnswerChange}
                              error={
                                userAnswers[answer.value]?.completed &&
                                userAnswers[answer.value]?.value !== 'yes' &&
                                userAnswers[answer.value]?.value !== 'na'
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            )}
            {message && (
              <div
                className="question-step__message"
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            )}
            {name === "intro" && (
              <CollapsibleList openedList data={requiredList} name="results-intro" />
            )}
            {name === "start" && (
              <div className="question-step__terms">
                <button
                  className={classNames(
                    "question-step__terms-check",
                    isTermsCheck && "question-step__terms-check_active"
                  )}
                  onClick={handleTermsCheck}
                >
                  {isTermsCheck && (
                    <svg
                      className="question-step__terms-check-icon"
                      width="6"
                      height="5"
                      viewBox="0 0 6 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.22618 5.34058e-05L6 0.504345L2.64485 5.00005H1.87103L0 2.56443L0.773821 1.89383L2.25794 3.18138L5.22618 5.34058e-05Z" fill="white"/>
                    </svg>
                  )}
                  I agree with the
                </button>
                &nbsp;<a href='/terms' target='_blank'>terms of use</a>
              </div>
            )}
            {name === "finish" && (
              <>
                <CollapsibleList mod="simple" data={answersRequiredList} name="results-finish" />
                {questionsIncompleted ? (
                  <div className="question-step__info-message">
                    All questions have to be answered to finish the self-assessment
                  </div>
                ) : (
                  <div className="question-step__recaptcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={isDev ? process.env.GATSBY_RECAPTCHA_SITEKEY_DEV : process.env.GATSBY_RECAPTCHA_SITEKEY}
                      onChange={handleReCaptchaChange}
                      hl="en"
                    />
                  </div>
                )}
              </>
            )}
            <div className="question-step__continue-button-wrap">
              <Button
                className="question-step__button question-step__button_back"
                type="button"
                mod="link"
                onClick={() => {
                  if (name === "start") {
                    navigate(steps[activeStep].url);
                  } else {
                    navigate(
                      activeStep > 0
                        ? steps[activeStep].url
                        : "/quiz"
                    );
                  }
                }}
              >
                <span className="question-step__button-caret-left" />
                Back
              </Button>
              {activeStep == stepsLength + 1 ? (
                <Button
                  onClick={setNewAnswers}
                  className={classNames(
                    "question-step__button btn btn_default",
                    isFormSubmitting && "question-step__button_processing"
                  )}
                  disabled={!isRecaptchaValidation}
                >
                  {isFormSubmitting ? (
                    <>
                      Sending
                      <span className="question-step__spinner" />
                    </>
                  ) : (
                    <>
                      Finish
                      <span className="question-step__button-caret-right" />
                    </>
                  )}
                </Button>
              ) : (
                <Button
                  onClick={handleContinueClick}
                  className="question-step__button question-step__button_continue"
                  disabled={!isValidatingFields || (name === "start" && !isTermsCheck) || (name !== "start" && disabledContinue)}
                >
                  {(activeStep > 0 && activeStep !== stepsLength) ? (
                    <>
                      Next
                      {/* Step&nbsp;
                      <div className="question-step__counts">
                        <span className="question-step__counts-active">{activeStep + 1}</span>
                        <span className="question-step__counts-separator">/</span>
                        <span className="question-step__counts-total">{stepsLength}</span>
                      </div> */}
                    </>
                  ) : (
                    <>
                      Continue
                    </>
                  )}
                  <span className="question-step__button-caret-right" />
                </Button>
              )}
            </div>
          </div>
          </div>
        </div>
      </div>
      <div className="question-step__footer">
        <FooterQuiz />
      </div>
    </section>
  );
};

export default QuestionStepComponent;
