import React, {
  FunctionComponent,
  ChangeEventHandler,
  MouseEventHandler,
} from "react";
import classNames from "classnames";
import "./Answer.scss";

interface CommonAnswerProps {
  type: "checkbox" | "radio";
  value: string;
  name: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick: MouseEventHandler;
  mod: string;
  text: string;
}

const CommonAnswer: FunctionComponent<CommonAnswerProps & {
  className?: string;
}> = props => {
  const {
    type,
    value,
    name,
    onChange,
    onClick,
    checked,
    className,
    mod,
    text,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={classNames(
        "answer",
        className,
        type === "checkbox" && "answer_with-checkbox",
        mod && mod
      )}
    >
      <input
        type={type}
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        className="answer__input"
        title={text}
      />
      <div className="answer__wrap">
        {text}
      </div>
    </label>
  );
};

const AnswerComponent: FunctionComponent<CommonAnswerProps &
  Answer> = props => {
  const {
    type,
    value,
    name,
    onChange,
    onClick,
    checked,
    text,
    mod
  } = props;

  return (
    <CommonAnswer
      type={type}
      value={value}
      name={name}
      text={text}
      onChange={onChange}
      onClick={onClick}
      checked={checked}
      mod={mod}
    />
  );
};

export default AnswerComponent;
