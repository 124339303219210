import React, { FunctionComponent, useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import "./InfoPopover.scss";

interface InfoPopoverProps {
  title: string;
  children: ReactNode;
}

const InfoPopover: FunctionComponent<InfoPopoverProps> = props => {
  const { title, children } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className="info-popover">
      <Popover
        isOpen={isPopoverOpen}
        positions={["bottom"]}
        align="end"
        padding={10}
        reposition={true}
        onClickOutside={() => setIsPopoverOpen(false)}
        containerClassName="info-popover__drop"
        content={children}
      >
        <div
          className="info-popover__toggle"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <div className="info-popover__toggle-icon">
            <svg
              className="info-popover__toggle-icon-i"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00004 13.6666C3.31804 13.6666 0.333374 10.6819 0.333374 6.99992C0.333374 3.31792 3.31804 0.333252 7.00004 0.333252C10.682 0.333252 13.6667 3.31792 13.6667 6.99992C13.6667 10.6819 10.682 13.6666 7.00004 13.6666ZM7.00004 12.3333C8.41453 12.3333 9.77108 11.7713 10.7713 10.7712C11.7715 9.77096 12.3334 8.41441 12.3334 6.99992C12.3334 5.58543 11.7715 4.22888 10.7713 3.22868C9.77108 2.22849 8.41453 1.66659 7.00004 1.66659C5.58555 1.66659 4.229 2.22849 3.2288 3.22868C2.22861 4.22888 1.66671 5.58543 1.66671 6.99992C1.66671 8.41441 2.22861 9.77096 3.2288 10.7712C4.229 11.7713 5.58555 12.3333 7.00004 12.3333V12.3333ZM6.33337 3.66659H7.66671V4.99992H6.33337V3.66659ZM6.33337 6.33325H7.66671V10.3333H6.33337V6.33325Z"
                fill="#111111"
              />
            </svg>
          </div>
          <div className="info-popover__toggle-label">{title}</div>
        </div>
      </Popover>
    </div>
  );
};

export default InfoPopover;
