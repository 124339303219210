import React, {
  forwardRef,
  ElementType,
  Ref,
  HTMLAttributes,
  FunctionComponent,
} from "react";
import classNames from "classnames";
import "./Button.scss";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  component?: ElementType;
  type?: "button" | "submit" | "cancel";
  size?: string;
  mod?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
}

const ButtonComponent: FunctionComponent<Props & {
  buttonRef: Ref<HTMLButtonElement>;
}> = props => {
  const {
    component: Component = "button",
    className = undefined,
    children = null,
    type = "button",
    disabled = false,
    fullWidth = false,
    size = "",
    mod = "default",
    buttonRef,
    ...restProps
  } = props;

  return (
    <Component
      className={classNames(
        "btn",
        size && `btn_${size}`,
        mod && `btn_${mod}`,
        fullWidth && "btn_fullwidth",
        className
      )}
      type={type}
      disabled={disabled}
      ref={buttonRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      {children}
    </Component>
  );
};

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ButtonComponent {...props} buttonRef={ref} />
));

export default Button;
