import React, { FunctionComponent } from "react";
import QuestionStepComponent from "../components/Quiz/QuestionStep/QuestionStep";
import { Step } from "../components/Quiz/types";
import { STEPS_TYPES } from "../constants/quiz";

interface QuizTemplateProps {
  pageContext: {
    step: Step;
    title: Step;
    id: string;
    order: number;
    basePath: string;
    steps: Step[];
  };
}

const QuizStepTemplate: FunctionComponent<QuizTemplateProps> = ({
  pageContext,
}) => {
  const { step, steps } = pageContext;
  const questionSteps: any[] = [];
  let k = 0;

  steps.map(item => {
    if (item.type === STEPS_TYPES.QUESTION) {
      questionSteps.push(item);
    }
    return questionSteps;
  });

  questionSteps.map((item, index: number) => {
    if (item.name === step.name) {
      k = index + 1;
    }
    return questionSteps;
  });

  switch (step.type) {
    case STEPS_TYPES.QUESTION: {
      return (
        <QuestionStepComponent
          name={step.name}
          answers={step.answers}
          multipleAnswers={step.multipleAnswers}
          sideTitle={step.sideTitle}
          sideImage={step.sideImage}
          message={step.message}
          title={step.title}
          description={step.description}
          key={step.name}
          activeStep={pageContext.order > 0 ? pageContext.order - 1 : pageContext.order}
          steps={steps.map(step => ({
            type: step.type,
            name: step.name,
            id: step.name,
            url: `${pageContext.basePath}/${pageContext.id}/${step.name}`,
            title: step.title,
            answers: step.answers,
            description: step.description
          }))}
        />
      );
    }

    default: {
      return <div>Wrong step type</div>;
    }
  }
};

export default QuizStepTemplate;
