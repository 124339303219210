import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import Answer from "../Answer/Answer";
import "./Question.scss";

interface QuestionProps {
  text: string;
	required?: boolean;
	name: string;
	userAnswers: Array;
	onChange: ChangeEventHandler<HTMLInputElement>;
  onClick: MouseEventHandler;
	error?: boolean;
}

const Question: FunctionComponent<QuestionProps> = props => {
  const { text, required, name, userAnswers, onChange, onClick, error } = props;

	const answers = [
    { title: "Yes", value: "yes" },
    { title: "No", value: "no" },
    { title: "N/A", value: "na" },
    { title: "Unknown", value: "unknwn" }
  ];

  return (
    <div className="question">
			{text && (
				<div className="question__title">
					{text}
					{/* {required && <sup>*</sup>} */}
				</div>
			)}
			<ul className="question__list">
				{answers.map(item => (
					<li
						key={`${name}-${item.value}`}
						className="question__list-item"
					>
						<Answer
							checked={
								userAnswers[name]?.value.includes(`${item.value}`) && userAnswers[name]?.completed
							}
							onClick={onClick}
							onChange={onChange}
							type="radio"
							name={name}
							value={`${item.value}`}
							text={item.title}
						/>
					</li>
				))}
			</ul>
			{error && (
				<div className="question__required-message">
					To meet CASA minimum requirements for the self-assessment this control should be implemented and deployed within the platform. Please visit the <a href='/#section-features' target='_blank'>FAQ</a> for all required controls.
				</div>
			)}
    </div>
  );
};

export default Question;
