import sideImage1 from "../../../assets/images/image-1.png";
import sideImage2 from "../../../assets/images/image-2.png";
import sideImage3 from "../../../assets/images/image-3.png";
import sideImage4 from "../../../assets/images/image-4.png";
import sideImage5 from "../../../assets/images/image-5.png";
import sideImage6 from "../../../assets/images/image-6.png";
import sideImage7 from "../../../assets/images/image-7.png";
import sideImage8 from "../../../assets/images/image-8.png";
import sideImage9 from "../../../assets/images/image-9.png";
import sideImage10 from "../../../assets/images/image-10.png";
import sideImage11 from "../../../assets/images/image-11.png";
import sideImage12 from "../../../assets/images/image-12.png";
import sideImage13 from "../../../assets/images/image-13.png";
import sideImage14 from "../../../assets/images/image-14.png";
import sideImage15 from "../../../assets/images/image-15.png";
import sideImage16 from "../../../assets/images/image-16.png";

export const getImage = (image: string) => {
  switch (image) {
    case "sideImage1":
      return sideImage1;
    case "sideImage2":
      return sideImage2;
    case "sideImage3":
      return sideImage3;
    case "sideImage4":
      return sideImage4;
    case "sideImage5":
      return sideImage5;
    case "sideImage6":
      return sideImage6;
    case "sideImage7":
      return sideImage7;
    case "sideImage8":
      return sideImage8;
    case "sideImage9":
      return sideImage9;
    case "sideImage10":
      return sideImage10;
    case "sideImage11":
      return sideImage11;
    case "sideImage12":
      return sideImage12;
    case "sideImage13":
      return sideImage13;
    case "sideImage14":
      return sideImage14;
    case "sideImage15":
      return sideImage15;
      case "sideImage16":
        return sideImage16;
    default:
      return "";
  }
};
